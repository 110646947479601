import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";
import { StyledGridRow } from "../../commons/Grid";
import { StyledMap } from "../OpenLayers/style";
import { StyledButton } from "../Button/style";

export const StyledBeekeepersWrapper = styled.div`
  position: relative;
  margin-bottom: ${Rem(40)};
  > ${StyledGridRow} {
    position: unset;
  }
  @media (${BP.desktop}) {
    display: flex;
    margin-bottom: 0;

    > ${StyledGridRow} {
      position: relative;
      @media (${BP.desktop}) {
        max-width: ${Rem(1500)};
      }
    }
  }
`;
export const StyledHivesText = styled.div`
  font-size:${Rem(20)};
  padding-bottom:${Rem(10)};
  text-shadow: 1px 1px 1px #AAA;
  @media (${BP.desktop}) {
    font-size:${Rem(29)};
    padding-bottom:${Rem(10)};
  }
`;

export const StyledHivesButton = styled.div`
  position: absolute;
  top: ${Rem(15)};
  left: ${Rem(15)};
  z-index: 4;
  @media (${BP.desktop}) {
    right: ${Rem(15)};
    left: auto;
  }
  ${StyledButton} {
    font-size: ${Rem(20)};
    height: ${Rem(35)};
    box-shadow: 1px 3px 3px 3px rgba(0, 0, 0, 0.3);
    @media (${BP.desktop}) {
      font-size: ${Rem(27)};
      height: ${Rem(45)};
    }
  }
`;

export const StyledBeekeepersMap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  right: 0;

  & .gcd-txt-control {
    height: 37px;
  }

  ${StyledGridRow} {
    max-width: none;
    padding: 0;
  }

  ${StyledMap} {
    height: 40vh;
    @media (${BP.desktop}) {
      height: ${Rem(400)};
    }

    &:before {
      display: none;
    }
  }

  @media (${BP.desktop}) {
    position: relative
    display: flex;
    width: calc(100%);
  }

  .map__popup {
    max-width: ${Rem(200)};

    a {
      display: block;
      text-align: center;
      font-size: 14px;
      padding: 5px;
      margin-top: 5px;
      color: rgba(255,255,255,1);
      background-color: rgba(255,176,0,1);
      text-decoration: none;
    }
  }
`;
